import { ErrorCode, OnpreoErrors } from "./codes";
// import chalk from "chalk";

/**
 * A chainable error, that*/
export class ApiError extends Error {
    cause?: any;
    code: ErrorCode;
    description?: string;
    payload?: any;

    constructor(code: ErrorCode, cause?: any, description?: string, payload?: any) {
        super("[" + code + "]" + OnpreoErrors[code].message, { cause });
        // Behält den richtigen Stack-Trace für die Stelle bei, an der unser Fehler ausgelöst wurde (nur bei V8 verfügbar)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ApiError);
        }
        this.cause = cause;
        this.code = code;
        this.name = "ApiError";

        this.description = description;
        this.payload = payload;
    }

    print() {
        console.log("*Error code*:", this.code);
        console.log("*Error message*:", this.toString());
        console.log("*Cause*:", this.cause);
        console.log("*Description*:", this.description);
        console.log("*Payload*:", this.payload);
    }

    toObject() {}
}
