import { useSession } from "next-auth/react";
import { Fragment, useEffect } from "react";
import { useRouter } from "next/router";

const SessionChecker = () => {
    const { data: session, status } = useSession();
    const router = useRouter();

    useEffect(() => {
        if (status === "authenticated") {
            (async () => await router.push("/dashboard"))();
        }
    }, [status]);

    useEffect(() => {
        if (status === "unauthenticated") {
            (async () => await router.push("/"))();
        }
    }, [status, router.route]);

    return <Fragment />;
};

export default SessionChecker;
