import { createAppAsyncThunk } from "./store";
import { LoadOptions, SaveFormThunkArgs } from "@onpreo/components";
import { setTotal, setType, setUserByIndex, setUserById, setUsers, setCurrentUser } from "./user";
import { createAsyncThunk, Dispatch } from "@reduxjs/toolkit";
import { ImageDetails, UserDocument, WorkspaceDocument } from "@onpreo/database";
import { FileDescriptor, imageHandler } from "@onpreo/toolbox/src/client";
import { onpreoClient } from "@onpreo/upsy-daisy/client";
import { createFailureNotification, createNotification, enqueueSnackbar } from "@onpreo/slices";
import { PROTOCOL, RUNNER_URL } from "../utils/secrets";
import { setWorkspace } from "./workspace";
import { WorkspaceLandingPage } from "src/pages/users/landing-page";
import workspaceService from "./services";

// type Range = [from: number, to: number];
// Utility function to check for non loaded indicies ranges in range
// NOTE: This function could be used to get already loaded user documents from cache
// const noneLoaded = (array: (object | undefined)[], range: Range): Range[] => {
//    // nothing is loaded
// }

// The main loading thunk, that provides the SmartTable with data
export const $loadUsers = createAppAsyncThunk("$loadUsers", async (load: LoadOptions, thunkAPI) => {
    // NOTE: a sophisticated implementation would check for ranges which are loaded and retrieve them from a cache.
    // through the noneLoaded function above
    thunkAPI.dispatch(setType("loading"));
    try {
        const response = await onpreoClient.post<{ total: number; users: UserDocument[] }>("/api/users", load);

        const { total, users } = response.data;

        thunkAPI.dispatch(setUsers({ range: load.range, users }));
        thunkAPI.dispatch(setTotal(total));
        thunkAPI.dispatch(setCurrentUser(null));
    } catch (err) {
        thunkAPI.dispatch(setType("error"));
    }
});

export const $saveUserByIndex = createAppAsyncThunk("$saveUserByIndex", async ({ id, value }: SaveFormThunkArgs<UserDocument>, thunkAPI) => {
    if (typeof id !== "number") return;
    const userId = thunkAPI.getState().user.users[id]?._id;
    if (userId === undefined) {
        console.error("saveUserByIndex: no user for index " + id);
        return;
    }
    const response = await onpreoClient.put<{ value: UserDocument }>("/api/user/" + userId, value);
    thunkAPI.dispatch(setUserByIndex({ index: id, user: response.data.value }));
});

export const $saveUserById = createAppAsyncThunk("$saveUserById", async ({ id, value }: SaveFormThunkArgs<UserDocument>, thunkAPI) => {
    if (id === undefined) {
        console.error("saveUserByIndex: no user for index " + id);
        return;
    }
    const response = await onpreoClient.put<{ value: UserDocument }>("/api/user/" + id, value);
    thunkAPI.dispatch(setUserById({ id, user: response.data.value }));
    console.log("Update user successful");
});

export const $saveWorkspaceData = createAppAsyncThunk("$saveUserWorkspaceData", async ({ id, value }: any, thunkAPI) => {
    if (id === undefined) {
        console.error("saveUserByIndex: no user for index " + id);
        return;
    }
    const response = await onpreoClient.put<{ value: any }>("/api/workspace/" + id, value);
    thunkAPI.dispatch(setUserById({ id, user: response.data.value }));
    thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
});

export const $uploadAsset = createAppAsyncThunk(
    "$uploadAsset",
    async ({ id, file, type, fileName, isSecure }: { id: string; file: Blob; type?: string; fileName?: string; isSecure?: boolean }, thunkAPI) => {
        const url = `/api/workspace/${id}/asset`;
        const headers = { type };
        const descriptor: FileDescriptor = { originalFilename: fileName ?? (file as any).name, mimetype: file.type };
        const response = await onpreoClient.post(url, { ...descriptor, isSecure }, { headers });
        await imageHandler.uploadPreSignedFile(response.data.presigned, file, isSecure);
        thunkAPI.dispatch(enqueueSnackbar(createNotification("Dokument erfolgreich hochgeladen", "success")));
        return response;
    }
);

export const $removeAsset = createAppAsyncThunk(
    "$removeAsset",
    async ({ id, details, isSecure }: { id: string; details: ImageDetails; isSecure?: boolean }, thunkAPI) => {
        try {
            const url = `/api/workspace/${id}/remove-asset`;
            const response = await onpreoClient.delete(url, {
                data: {
                    ...details,
                    isSecure
                }
            });
            thunkAPI.dispatch(enqueueSnackbar(createNotification("Dokument erfolgreich gelöscht", "success")));
            return true;
        } catch (err) {
            return false;
        }
    }
);

export const $getSmashleadForms = createAppAsyncThunk(
    "$getSmashleadForms",
    async ({ id, content, contentId, type }: { id: string; content: string; contentId?: string; type?: string }, thunkAPI) => {
        try {
            const url = `/api/user/${id}/forms`;
            const response = await onpreoClient.get(url, { responseType: "blob", params: { content, contentId, type } });
            thunkAPI.dispatch(enqueueSnackbar(createNotification("Inhalte erfolgreich erstellt", "success")));
            return response.data;
        } catch (err) {
            return false;
        }
    }
);

export const $getPdfContent = createAppAsyncThunk("$getPdfContent", async ({ id }: { id: string }, thunkAPI) => {
    try {
        const url = `/api/user/pdf`;
        const response = await onpreoClient.get(url);
        return response.data;
    } catch (err) {
        return false;
    }
});

export const $sendOnboardingSuccessfulEmail = createAsyncThunk("$sendOnboardingSuccessfulEmail", async (userId: string, thunkAPI) => {
    const url = `/api/user/${userId}`;
    const response = await onpreoClient.post(url);
    thunkAPI.dispatch(enqueueSnackbar(createNotification("Mail erfolgreich gesendet", "success")));
    return response.data;
});

export const $createChecklistOrGuide = createAsyncThunk(
    "$createChecklistOrGuide",
    async ({ userId, contentId, contentType }: { userId: string; contentId: string; contentType: string }, thunkAPI) => {
        try {
            const url = `${PROTOCOL}${RUNNER_URL}/automation/content`;
            const response = await onpreoClient.get(url, { params: { userId, contentId, contentType } });
            return response.data;
        } catch (err) {
            console.log(err);
            thunkAPI.dispatch(enqueueSnackbar(createFailureNotification()));
        }
    }
);

export const $getUserById = createAsyncThunk("contact/$getUserById", async (userId: string, thunkAPI) => {
    const url = `/api/user/${userId}`;

    const response = await onpreoClient.get(url);
    thunkAPI.dispatch(setCurrentUser(response.data.user));
    if (response.data.workspace) thunkAPI.dispatch(setWorkspace(response.data.workspace));

    return response.data;
});

export const $sendDealEmail = createAsyncThunk("$sendDealEmail", async (userId: string, thunkAPI) => {
    const url = `/api/user/${userId}/deal`;
    const response = await onpreoClient.post(url);
    thunkAPI.dispatch(enqueueSnackbar(createNotification("Mail erfolgreich gesendet", "success")));
    return response.data;
});

export const $addTeamUser = createAsyncThunk(
    "$addTeamUser",
    async (
        body: {
            name: string;
            email: string;
            role: string;
            workspace: string;
        },
        thunkAPI
    ) => {
        const url = `/api/workspace/${body.workspace}/team-user`;
        const response = await onpreoClient.post(url, body);
        thunkAPI.dispatch(enqueueSnackbar(createNotification("Einladung erfolgreich gesendet", "success")));
        if (response.data.workspace) thunkAPI.dispatch(setWorkspace(response.data.workspace));
        return response.data;
    }
);

export const $updateWorkspaceData = createAppAsyncThunk("$updateWorkspaceData", async ({ id, value }: SaveFormThunkArgs<WorkspaceDocument>, thunkAPI) => {
    if (id === undefined) {
        console.error("saveWorkspaceById: no workspace for index " + id);
        return;
    }
    const response = await onpreoClient.patch<{ value: WorkspaceDocument }>("/api/workspace/" + id, value);
    thunkAPI.dispatch(setWorkspace(response.data.value));
    thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
});

export const $updateWorkspaceLandingPage = createAsyncThunk(
    "workspace/$updateWorkspaceLandingPage",
    async ({ id, value }: SaveFormThunkArgs<WorkspaceLandingPage>, thunkAPI) => {
        const response = await onpreoClient.patch<{ value: WorkspaceLandingPage }>(`/api/workspace/${id}/landing-page`, value);
        //@ts-ignore
        thunkAPI.dispatch(setWorkspace(response.data.value));
        thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
    }
);

export const $addLandingPageProofLogo = createAsyncThunk("workspace/$addLandingPageProofLogo", async ({ workspaceId, data }: any, thunkAPI) => {
    const response = await workspaceService.addLandingPageProofLogo({ workspaceId, data });
    thunkAPI.dispatch(setWorkspace(response.data.workspace));
    thunkAPI.dispatch(enqueueSnackbar(createNotification("Bild erfolgreich hochgeladen", "success")));
    return response.data;
});

export const $deleteLandingPageProofLogo = createAsyncThunk("workspace/$deleteLandingPageProofLogo", async ({ workspaceId, index }: any, thunkAPI) => {
    const response = await workspaceService.deleteLandingPageProofLogo({ workspaceId, index });
    thunkAPI.dispatch(setWorkspace(response.data.workspace));
    return response.data;
});

export const $addLandingPageRealEstates = createAsyncThunk("workspace/$addLandingPageRealEstates", async ({ workspaceId, data }: any, thunkAPI) => {
    const response = await workspaceService.addLandingPageRealEstates({ workspaceId, data });
    thunkAPI.dispatch(setWorkspace(response.data.workspace));
    return response.data;
});

export const $addLandingPageRealEstatesImage = createAsyncThunk(
    "workspace/$addLandingPageRealEstatesImage",
    async ({ workspaceId, reId, data }: any, thunkAPI) => {
        const response = await workspaceService.addLandingPageRealEstatesImage({ workspaceId, reId, data });
        thunkAPI.dispatch(setWorkspace(response.data.workspace));
        return response.data;
    }
);

export const $deleteLandingPageRealEstate = createAsyncThunk("workspace/$deleteLandingPageRealEstate", async ({ workspaceId, reId }: any, thunkAPI) => {
    const response = await workspaceService.deleteLandingPageRealEstate({ workspaceId, reId });
    thunkAPI.dispatch(setWorkspace(response.data.workspace));
    return response.data;
});

export const $addPublicationImage = createAsyncThunk("workspace/$addPublicationImage", async ({ workspaceId, data }: any, thunkAPI) => {
    const response = await workspaceService.addPublicationImage({ workspaceId, data });
    // thunkAPI.dispatch(setWorkspace(response.data.workspace));
    // thunkAPI.dispatch(enqueueSnackbar(createNotification("Bild erfolgreich hochgeladen", "success")));
    return response.data;
});

export const $deletePublicationImage = createAsyncThunk("workspace/$deletePublicationImage", async ({ workspaceId, index }: any, thunkAPI) => {
    const response = await workspaceService.deletePublicationImage({ workspaceId, index });
    // thunkAPI.dispatch(setWorkspace(response.data.workspace));
    return response.data;
});

export const $createBookedContent = createAsyncThunk(
    "$createBookedContent",
    async ({ userId, contentId, contentType, region }: { userId: string; contentId: string; contentType: string; region: string }, thunkAPI) => {
        // const response = await workspaceService.reducePDFSize(userId, contentId, contentType, region);
        const url = `${PROTOCOL}${RUNNER_URL}/automation/compress`;
        const response = await onpreoClient.get(url, { responseType: "arraybuffer", params: { userId, contentId, contentType, region } });
        return response.data;
    }
);

export const $createMockups = createAsyncThunk(
    "$createMockups",
    async ({ userId, contentId, contentType, region }: { userId: string; contentId: string; contentType: string; region: string }, thunkAPI) => {
        // const url = `/api/workspace/${workspaceId}/mockups`;
        // const response = await onpreoClient.post(url, contents);
        const url = `${PROTOCOL}${RUNNER_URL}/automation/mockup`;
        const response = await onpreoClient.get(url, { params: { userId, contentId, contentType, region } });
        return response.data;
    }
);

export const $uploadContent = createAsyncThunk(
    "workspace/$uploadContent",
    async (
        {
            id,
            file,
            fileName,
            isSecure,
            contentId,
            contentType
        }: { id: string; file: Blob; fileName?: string; isSecure?: boolean; contentId?: string; contentType?: string },
        thunkAPI
    ) => {
        const response = await workspaceService.uploadContent(id, file, fileName, isSecure, contentId, contentType);
        return response.data;
    }
);

export const $uploadWorkspaceContents = createAsyncThunk("workspace/$uploadWorkspaceContents", async (payload: any, thunkAPI) => {
    const response = await workspaceService.uploadWorkspaceContents(payload);
    thunkAPI.dispatch(setWorkspace(response.data.workspace));
    return response.data;
});

export const $createLeadGeneratorsForLP = createAsyncThunk("$createLeadGeneratorsForLP", async ({ userId }: { userId: string }, thunkAPI) => {
    const url = `/api/user/${userId}/create-content`;
    const response = await onpreoClient.post(url);
    thunkAPI.dispatch(setWorkspace(response.data.workspace));
    return response.data;
});

export const $getWorkspaceById = createAsyncThunk("contact/$getWorkspaceById", async ({ workspaceId }: { workspaceId: string }, thunkAPI) => {
    const url = `/api/workspace/${workspaceId}`;
    const response = await onpreoClient.get(url);
    thunkAPI.dispatch(setWorkspace(response.data.workspace));
    return response.data;
});

export const $createJson = createAsyncThunk("$createJson", async ({ workspaceId, contents }: { workspaceId: string; contents: any }, thunkAPI) => {
    const url = `/api/workspace/${workspaceId}/create-json`;
    const response = await onpreoClient.post(url, contents);
    return response.data;
});

export const $createWebsites = createAsyncThunk("$createWebsites", async ({ workspaceId, contents }: { workspaceId: string; contents: any }, thunkAPI) => {
    const url = `/api/workspace/${workspaceId}/create-website`;
    const response = await onpreoClient.post(url, contents);
    return response.data;
});

export const $importActiveCampaignLeads = createAsyncThunk(
    "$importActiveCampaignLeads",
    async (
        {
            account,
            token,
            userId,
            stat = true,
            offset,
            limit,
            locationTag
        }: { account: string; token: string; userId: string; stat?: boolean; offset: string; limit: string; locationTag?: string },
        thunkAPI
    ) => {
        const url = `/api/active-campaign/${userId}`;
        const response = await onpreoClient.get(url, { params: { account, token, stat, offset, limit, locationTag } });

        thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich hochgeladen", "success")));

        return response.data;
    }
);

export const $deleteAllmigratedData = createAsyncThunk("$deleteAllmigratedData", async ({ userId }: { userId: string }, thunkAPI) => {
    const url = `/api/active-campaign/${userId}`;
    const response = await onpreoClient.delete(url);

    thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gelöscht", "success")));
    return response.data;
});

export const $uploadIframeLinks = createAsyncThunk(
    "workspace/$uploadWorkspaceContents",
    async ({ workspaceId, contents }: { workspaceId: string; contents: any }, thunkAPI) => {
        const response = await workspaceService.uploadIframeLinks(workspaceId, contents);
        return response.data;
    }
);

export const $deleteContents = createAsyncThunk("$deleteContents", async ({ workspaceId }: { workspaceId: string }, thunkAPI) => {
    const url = `/api/workspace/${workspaceId}/contents`;
    const response = await onpreoClient.delete(url);
    thunkAPI.dispatch(setWorkspace(response.data.workspace));
    thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gelöscht", "success")));
    return response.data;
});

export const $fetchInvoicesList = createAsyncThunk("$fetchInvoicesList", async ({ workspaceId }: { workspaceId: string }, thunkAPI) => {
    const invoices = await workspaceService.getInvoicesList(workspaceId);
    return invoices;
});

export const $fetchValuationsCount = createAsyncThunk("$fetchInvoicesList", async ({ workspaceId }: { workspaceId: string }, thunkAPI) => {
    console.log("ws: " + workspaceId);
    const { count, workspace } = await workspaceService.getValuationCount(workspaceId);
    console.log("count: " + count);
    thunkAPI.dispatch(setWorkspace(workspace));
    return count;
});

export const $reducePDFSize = createAsyncThunk("$reducePDFSize", async ({ buffer }: { buffer: any }, thunkAPI) => {
    // const response = await workspaceService.reducePDFSize(buffer);
    // return response.data;
});

export const $disconnectAreaButler = createAppAsyncThunk("$disconnectAreaButler", async ({ id }: { id: string }, thunkAPI) => {
    if (id === undefined) {
        console.error("saveWorkspaceById: no workspace for index " + id);
        return;
    }
    const response = await onpreoClient.patch("/api/workspace/areaButler", { id });
    thunkAPI.dispatch(setWorkspace(response.data.value));
    thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
});
