import jwt from "jsonwebtoken";

export class TokenGenerator {
    private readonly SESSION_SECRET: string;

    constructor(sessionSecret: string) {
        this.SESSION_SECRET = sessionSecret;
    }

    /**
     * Used to create a shareable queryToken for onpreo services that do not rely on next auth session and dont have
     * their own login, such as expose assistant and price assistant. The token is the consumed by the
     * loaders.checkQueryToken function to secure the endpoints.
     *
     * @param realEstateId ObjectId
     * @param userId ObjectId
     */
    public createQueryToken = (realEstateId: string, userId: string) => {
        // NOTE: this token is valid for 48 hours, this should be enough for a running assistant session
        return jwt.sign({ realEstateId, userId }, this.SESSION_SECRET, { expiresIn: 60 * 60 * 48 });
    };
}
