import { Action, AsyncThunk, AsyncThunkOptions, AsyncThunkPayloadCreator, configureStore, createAsyncThunk, ThunkAction } from "@reduxjs/toolkit";
import userReducer from "./user";
import { injectStore } from "@onpreo/upsy-daisy/client";
import { sharedReducer } from "@onpreo/slices";
import workspaceReducer from "./workspace";

export const store = configureStore({
    reducer: {
        // This is where we add reducers.
        user: userReducer,
        workspace: workspaceReducer,
        ...sharedReducer
    }
});

if (typeof window !== "undefined") {
    injectStore(store);
}

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

type AsyncThunkConfig = {
    state: RootState;
    dispatch: AppDispatch;
};
// Using this we get correctly typed thunks
export const createAppAsyncThunk = <Returned extends any, ThunkArg = void>(
    typePrefix: string,
    payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg, AsyncThunkConfig>,
    options?: AsyncThunkOptions<ThunkArg, AsyncThunkConfig>
): AsyncThunk<Returned, ThunkArg, AsyncThunkConfig> => createAsyncThunk<Returned, ThunkArg>(typePrefix, payloadCreator, options);
