import { AxiosResponse } from "axios";
import type { UserType } from "../api/controllers/search";
import { onpreoClient } from "@onpreo/upsy-daisy/client";
import { FileDescriptor, imageHandler } from "@onpreo/toolbox";

export const headers = () => {
    return { "Content-Type": "application/json" };
};

export const search = async (query: string): Promise<AxiosResponse<{ result: UserType[] }>> => {
    const url = "/api/search";
    return await onpreoClient.post<{ result: UserType[] }>(url, { query });
};

const addLandingPageProofLogo = async ({ workspaceId, data }: any) => {
    const url = `/api/workspace/${workspaceId}/landing-page/proof-logo`;
    const descriptor: FileDescriptor = { originalFilename: data.fileName, mimetype: data.png.type };
    const response = await onpreoClient.post(url, descriptor);
    await imageHandler.uploadPreSignedFile(response.data.presigned, data.png);
    return response;
};

const deleteLandingPageProofLogo = async ({ workspaceId, index }: any) => {
    const url = `/api/workspace/${workspaceId}/landing-page/proof-logo/${index}`;
    return await onpreoClient.delete(url);
};

const addLandingPageRealEstates = async ({ workspaceId, data }: any) => {
    const url = `/api/workspace/${workspaceId}/landing-page/real-estates`;
    return await onpreoClient.patch(url, data);
};

const addLandingPageRealEstatesImage = async ({ workspaceId, reId, data }: any) => {
    const url = `/api/workspace/${workspaceId}/landing-page/real-estates/${reId}`;
    const descriptor: FileDescriptor = { originalFilename: data.fileName, mimetype: data.png.type };
    const response = await onpreoClient.post(url, descriptor);
    await imageHandler.uploadPreSignedFile(response.data.presigned, data.png);
    return response;
};

const deleteLandingPageRealEstate = async ({ workspaceId, reId }: any) => {
    const url = `/api/workspace/${workspaceId}/landing-page/real-estates/${reId}`;
    return await onpreoClient.delete(url);
};

const addPublicationImage = async ({ workspaceId, data }: any) => {
    const url = `/api/workspace/${workspaceId}/publication/publication-images`;
    const descriptor: FileDescriptor = { originalFilename: data.fileName, mimetype: data.png.type };
    const response = await onpreoClient.post(url, descriptor);
    await imageHandler.uploadPreSignedFile(response.data.presigned, data.png);
    return response;
};

const deletePublicationImage = async ({ workspaceId, index }: any) => {
    const url = `/api/workspace/${workspaceId}/publication/publication-images/${index}`;
    return await onpreoClient.delete(url);
};

const uploadContent = async (id: string, file: Blob, fileName?: string, isSecure?: boolean, contentId?: string, contentType?: string) => {
    const url = `/api/workspace/${id}/contents`;
    const descriptor: FileDescriptor = { originalFilename: fileName ?? (file as any).name, mimetype: file.type };
    const response = await onpreoClient.post(url, { ...descriptor, isSecure, contentId, contentType });
    await imageHandler.uploadPreSignedFile(response.data.presigned, file, isSecure);
    return response;
};

const uploadWorkspaceContents = async ({ workspaceId, data, contentsPackage }: any) => {
    const url = `/api/workspace/${workspaceId}/contents`;
    return await onpreoClient.patch(url, { data, contentsPackage });
};

const uploadIframeLinks = async (workspaceId, contents) => {
    const url = `/api/workspace/${workspaceId}/iframes`;
    return await onpreoClient.patch(url, contents);
};

async function getInvoicesList(workspaceId: string = "0"): Promise<any[]> {
    const url = `/api/workspace/${workspaceId}/billing/invoice`;
    const {
        data: { invoices }
    } = await onpreoClient.get(url);
    return invoices;
}

async function getValuationCount(workspaceId: string = "0"): Promise<any> {
    const url = `/api/workspace/${workspaceId}/valuations`;
    const {
        data: { count, workspace }
    } = await onpreoClient.get(url);
    return { count, workspace };
}

const reducePDFSize = async (userId, contentId, contentType, region) => {
    const url = `/api/workspace/pdf`;
    const data = { userId, contentId, contentType, region };
    return await onpreoClient.get(url, { responseType: "arraybuffer", params: { ...data } });
};

const workspaceService = {
    addLandingPageProofLogo,
    deleteLandingPageProofLogo,
    addLandingPageRealEstates,
    addLandingPageRealEstatesImage,
    deleteLandingPageRealEstate,
    addPublicationImage,
    deletePublicationImage,
    uploadContent,
    uploadWorkspaceContents,
    uploadIframeLinks,
    getInvoicesList,
    getValuationCount,
    reducePDFSize
};

export default workspaceService;
