import type { AppProps } from "next/app";
import React, { Fragment, useEffect } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import Head from "next/head";
import CssBaseline from "@material-ui/core/CssBaseline";
import { onpreoTheme } from "@onpreo/components";
import { SessionProvider } from "next-auth/react";
import SessionChecker from "src/components/helper/session-checker";
import { store } from "src/store";
import { Provider } from "react-redux";
import { Snacker } from "@onpreo/slices";

export default function AdminApp({ Component, pageProps: { session, ...pageProps } }: AppProps) {
    useEffect(() => {
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector("#jss-server-side");
        if (jssStyles && jssStyles.parentElement) jssStyles.parentElement.removeChild(jssStyles);
    }, []);

    return (
        <Fragment>
            <Head>
                <title>onpreo - Admin-app</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Head>
            <ThemeProvider theme={onpreoTheme}>
                <SessionProvider session={session}>
                    <Provider store={store}>
                        <Snacker>
                            <CssBaseline />
                            <SessionChecker />
                            <Component {...pageProps} />
                        </Snacker>
                    </Provider>
                </SessionProvider>
            </ThemeProvider>
        </Fragment>
    );
}
