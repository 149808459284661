export const NEXTAUTH_SECRET = process.env.NEXTAUTH_SECRET;
export const MONGO_URL = process.env.MONGO_URL;

export const MJ_APIKEY_PUBLIC = process.env.MJ_APIKEY_PUBLIC;
export const MJ_APIKEY_PRIVATE = process.env.MJ_APIKEY_PRIVATE;

export const JWT_SECRET = process.env.JWT_SECRET;

export const USERNAME = process.env.USERNAME;

export const PASSWORD = process.env.PASSWORD;
export const EMAIL = process.env.EMAIL;

export const BROKER_APP_URL = process.env.NEXT_PUBLIC_BROKER_APP_URL;
export const DEMO_ASSISTANT_URL = process.env.NEXT_PUBLIC_DEMO_ASSISTANT_URL;
export const NODE_ENV = process.env.NODE_ENV;
export const PROTOCOL = NODE_ENV === "development" ? "http://" : "https://";
export const FORM_EXTENSION = NODE_ENV === "development" ? "xyz" : "io";

export const AWS_ACCESS_KEY = process.env.ONPREO_AWS_ACCESS_KEY;
export const AWS_BUCKET = process.env.AWS_BUCKET;
export const AWS_SECRET_ACCESS_KEY = process.env.ONPREO_AWS_SECRET_ACCESS_KEY;
export const AWS_REGION = "eu-central-1";
export const AWS_IMAGE_URL = `${PROTOCOL}${AWS_BUCKET}.s3.${AWS_REGION}.amazonaws.com/`;

export const SMASH_MONGO_URL = process.env.SMASH_MONGO_URL;
export const SMASH_STAGING_MONGO_URL = process.env.SMASH_STAGING_MONGO_URL;
export const RUNNER_URL = process.env.NEXT_PUBLIC_RUNNER_URL;

export const GOOGLE_MAPS_API_KEY = process.env.GOOGLE_MAPS_API_KEY;
