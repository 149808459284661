import { Workspace } from "@onpreo/database";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const initialWorkspaceState = {
    currentWorkspace: null,
    contentsCreated: false,
    landingPagesCreated: false
} as any;

export const workspaceSlice = createSlice({
    name: "workspace",
    initialState: initialWorkspaceState,
    reducers: {
        setWorkspace: (state, action: PayloadAction<Workspace | null>) => {
            state.currentWorkspace = action.payload;
        },
        setContentsCreated: (state, action: PayloadAction<boolean>) => {
            state.contentsCreated = action.payload;
        },
        setLandingPagesCreated: (state, action: PayloadAction<boolean>) => {
            state.landingPagesCreated = action.payload;
        }
    }
});

export const { setWorkspace, setContentsCreated, setLandingPagesCreated } = workspaceSlice.actions;
export default workspaceSlice.reducer;
