import { ErrorCode } from "./codes";
import { ApiError } from "./error";

import { Document } from "mongoose";

/**
 * Invariant (also known as assert)
 * Supply the function with any condition and if the control flow continues this condition is
 * "invariantly" true
 **/
export function invariant(cond: boolean, code: ErrorCode, description?: string, payload?: any, cause?: any): asserts cond {
    if (!cond) {
        throw new ApiError(code, cause, description, payload);
    }
}

/**
 * Wrap any callback or promise execution in a try/catch block
 * If the promise is rejected or the callback throws, a new ApiError (see ./error.ts) is constructed
 * with the throwed error being the cause and the other parameters to this function describing the error
 *
 * NOTE: This is just syntax sugar for wrapping everything in a try/catch block
 **/
export const wrapCatch = async <T = void>(
    callbackOrPromise: (() => Promise<T> | T) | Promise<T>,
    code: ErrorCode,
    description?: string,
    payload?: any
): Promise<T> => {
    try {
        if (typeof callbackOrPromise === "function") return await callbackOrPromise();
        else {
            return await callbackOrPromise;
        }
    } catch (err) {
        throw new ApiError(code, err, description, payload);
    }
};

export const wrapSave = (obj: Document) => wrapCatch(obj.save.bind(obj), "database-save-failed");

export const isSome = <T extends any>(some: T | undefined | null): some is T => {
    return some !== undefined && some !== null;
};

export const isNone = <T extends any>(none: T | undefined | null): none is null | undefined => !isSome<T>(none);

export type Awaitable<T> = PromiseLike<T> | T;
