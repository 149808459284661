import { CopyObjectCommand, GetObjectCommand, HeadObjectCommand, PutObjectCommand, S3 } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import path from "path";
import { ImageDetails } from "@onpreo/database/src";
import * as reduxToolkit from "@reduxjs/toolkit";
import { invariant, isSome, wrapCatch } from "@onpreo/upsy-daisy";
import { FileDescriptor } from "../../client";

/**
 * Class for handling server-side image uploads using presigned urls. This is the recommended way of dealing with serverless
 * functions which cannot handle big media throughput
 */
export class ImageHandler {
    private readonly AWS_CREDENTIALS: {
        readonly accessKeyId: string;
        readonly secretAccessKey: string;
        readonly sessionToken?: string;
    };
    private readonly AWS_BUCKET: string;
    private readonly AWS_BUCKET_PRIVATE: string;
    private readonly AWS_BUCKET_CLOUDMAILIN: string;
    private readonly AWS_IMAGE_URL: string;
    // private readonly AWS_SECRET_ACCESS_KEY: string;
    private readonly NODE_ENV: string;

    constructor(
        accessKey: string,
        secretKey: string,
        bucketName: string,
        imageUrl: string,
        nodeEnv: string,
        bucketNamePrivate?: string,
        bucketNameCloudmailin?: string
    ) {
        this.AWS_CREDENTIALS = { accessKeyId: accessKey, secretAccessKey: secretKey };
        // this.AWS_SECRET_ACCESS_KEY = secretKey;
        this.AWS_BUCKET = bucketName;
        this.AWS_IMAGE_URL = imageUrl;
        this.NODE_ENV = nodeEnv;
        this.AWS_BUCKET_PRIVATE = bucketNamePrivate;
        this.AWS_BUCKET_CLOUDMAILIN = bucketNameCloudmailin;
    }

    public checkIfFileExists = async (s3: S3, dir: string, path: string, extension: string, isSecure?: boolean, isActivityPdf?: boolean) => {
        const params = {
            Bucket: isActivityPdf ? this.AWS_BUCKET_CLOUDMAILIN : isSecure ? this.AWS_BUCKET_PRIVATE : this.AWS_BUCKET,
            Key: dir + `/${path + extension}`
        };
        const headObjectCommand = new HeadObjectCommand(params);
        const data = await wrapCatch(s3.send(headObjectCommand), "s3-check-exists-failed");
        return !!data.Metadata;
    };

    public uploadImage = async (descriptor: FileDescriptor, dir: string, isSecure?: boolean): Promise<ImageDetails & { presigned: string }> => {
        invariant(isSome(descriptor), "invalid-body");
        const id = reduxToolkit.nanoid();
        // parse original name
        const parsed = path.parse(descriptor.originalFilename);
        // Start reading our file
        // const readStream = fs.createReadStream((file as any).filepath);
        const fileName = descriptor.originalFilename.includes("Preis_Finder") ? descriptor.originalFilename : `${parsed.name}.${id}${parsed.ext}`;
        // AWS Upload
        const params = {
            Bucket: isSecure ? this.AWS_BUCKET_PRIVATE : this.AWS_BUCKET,
            ACL: isSecure ? "private" : "public-read",
            Key: dir + `/${fileName}`,
            ContentType: "image/jpeg",
            signatureVersion: "v4"
            // Expires: 3600
            // CacheControl: "max-age=31536000"
        };

        const s3 = new S3({ credentials: this.AWS_CREDENTIALS, region: "eu-central-1" });

        const putObjectCommand = new PutObjectCommand(params);
        const presigned = await wrapCatch(getSignedUrl(s3, putObjectCommand, { unhoistableHeaders: new Set(["x-amz-acl"]) }), "s3-presigned-error");

        return {
            id: id,
            filename: descriptor.originalFilename,
            originalName: descriptor.originalFilename,
            mimetype: descriptor.mimetype,
            src: this.AWS_IMAGE_URL + dir + `/${fileName}`,
            presigned: presigned
        };
    };

    public removeImage = async (path: string, dir: string, isSecure?: boolean, isActivityPdf?: boolean) => {
        const name = path.slice(path.lastIndexOf("/") + 1);
        const s3 = new S3({ credentials: this.AWS_CREDENTIALS, region: "eu-central-1" });

        let flag = true;
        let exists = await this.checkIfFileExists(s3, dir, name, "", isSecure, isActivityPdf);
        if (!exists) {
            exists = await this.checkIfFileExists(s3, dir, name, "", !isSecure, isActivityPdf);
            flag = false;
        }
        invariant(exists, "s3-file-missing");
        const params = {
            Bucket: isActivityPdf ? this.AWS_BUCKET_CLOUDMAILIN : isSecure && flag ? this.AWS_BUCKET_PRIVATE : this.AWS_BUCKET,
            Key: `${dir}/${name}`
        };
        await wrapCatch(() => s3.deleteObject(params), "s3-delete-failed");
    };

    public removeImageFromFolder = async (dir: string) => {
        const s3 = new S3({ credentials: this.AWS_CREDENTIALS, region: "eu-central-1" });

        const params = {
            Bucket: this.AWS_BUCKET,
            Prefix: dir + "/"
        };

        const objects = await s3.listObjectsV2(params);
        if (!objects.Contents) {
            console.log("----> The folder on S3 is empty <----");
            return;
        }

        // Convert object keys to the format required by deleteObjects
        const deleteParams = {
            Bucket: this.AWS_BUCKET,
            Delete: {
                Objects: objects.Contents.map(obj => ({ Key: obj.Key }))
            }
        };
        // Delete all objects in the folder on S3
        await wrapCatch(() => s3.deleteObjects(deleteParams), "s3-delete-failed");
    };

    public imageUrlToBase64 = async url => {
        const data = await fetch(url);
        const blob = await data.blob();
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => {
            return reader.result;
        };
    };

    public uploadFileBuffer = async (fileName: string, buffer: Buffer, dir: string, id: string, isSecure?: boolean): Promise<string> => {
        const params = {
            ACL: isSecure ? "private" : "public-read",
            Bucket: isSecure ? this.AWS_BUCKET_PRIVATE : this.AWS_BUCKET,
            Body: buffer,
            Key: dir + `/${fileName}`,
            CacheControl: "max-age=31536000",
            ContentType: "application/pdf"
        };

        const s3 = new S3({ credentials: this.AWS_CREDENTIALS, region: "eu-central-1" });
        const putObjectCommand = new PutObjectCommand(params);
        const data = await wrapCatch(s3.send(putObjectCommand), "s3-buffer-upload-failed");
        const location = `https://${isSecure ? this.AWS_BUCKET_PRIVATE : this.AWS_BUCKET}.s3.amazonaws.com/${dir}/${fileName}`;
        return location;
    };

    public generatePreseignedUrl = async (src: string, isSecure?: boolean) => {
        const split = src.split("/");
        const dir = split[3];

        const s3 = new S3({
            credentials: this.AWS_CREDENTIALS,
            region: "eu-central-1"
            // signatureVersion: "v4"
        });

        const name = src.slice(src.lastIndexOf("/") + 1);
        // check if the document is in public or private bucket
        let flag = true;
        let exists = await this.checkIfFileExists(s3, dir, name, "", isSecure);
        if (!exists) {
            exists = await this.checkIfFileExists(s3, dir, name, "", !isSecure);
            flag = false;
        }
        invariant(exists, "s3-file-missing");
        const params = {
            Bucket: isSecure && flag ? this.AWS_BUCKET_PRIVATE : this.AWS_BUCKET,
            Key: dir + `/${name}`,
            Expires: 43200 // 12 hours
        };

        const getObjectCommand = new GetObjectCommand(params);
        const presignedUrl = await wrapCatch(() => getSignedUrl(s3, getObjectCommand), "s3-presigned-error");
        return presignedUrl;
    };

    public copyFileToFolder = async (src: string, target: string, isSecure?: boolean) => {
        const split = src.split("/");
        const dir = split[3];

        const s3 = new S3({
            credentials: this.AWS_CREDENTIALS,
            region: "eu-central-1"
            // signatureVersion: "v4"
        });

        const name = src.slice(src.lastIndexOf("/") + 1);
        // check if the document is in public or private bucket
        let flag = true;
        let exists = await this.checkIfFileExists(s3, dir, name, "", isSecure);
        if (!exists) {
            exists = await this.checkIfFileExists(s3, dir, name, "", !isSecure);
            flag = false;
        }
        invariant(exists, "s3-file-missing");
        const params = {
            Bucket: isSecure && flag ? this.AWS_BUCKET_PRIVATE : this.AWS_BUCKET,
            Key: `${target}/${name}`,
            CopySource: `/${isSecure && flag ? this.AWS_BUCKET_PRIVATE : this.AWS_BUCKET}/${dir}/${name}`
        };

        const copyObjectCommand = new CopyObjectCommand(params);
        await wrapCatch(() => s3.send(copyObjectCommand), "s3-copy-error");

        const location = `https://${isSecure ? this.AWS_BUCKET_PRIVATE : this.AWS_BUCKET}.s3.amazonaws.com/${target}/${name}`;

        return location;
    };

    public getContentsDirectory = async (contentType: string, contentId: string, region: string, isMockup?: boolean) => {
        const s3 = new S3({
            credentials: this.AWS_CREDENTIALS,
            region: "eu-central-1"
        });

        const params = {
            Bucket: this.AWS_BUCKET_PRIVATE,
            Prefix: isMockup ? `content/mockup/${contentType}` : `content/${region}/${contentType}/${contentId}`
        };

        const response = await s3.listObjectsV2(params);
        return response?.Contents;
    };

    public getContentFile = async (key: string) => {
        const s3 = new S3({
            credentials: this.AWS_CREDENTIALS,
            region: "eu-central-1"
        });

        const params = {
            Bucket: this.AWS_BUCKET_PRIVATE,
            Key: key
        };

        const getObjectCommand = new GetObjectCommand(params);
        const presignedUrl = await wrapCatch(() => getSignedUrl(s3, getObjectCommand), "s3-presigned-error");
        return presignedUrl;
    };
}
