// In more complex cases this should be a folder...

import { Range } from "@onpreo/components";
import { UserDocument } from "@onpreo/database";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Our "cache" just has a list, whos entries though, since pages are not required to load in order,
// can contain undefined
const initialUsers = {
    users: [] as (UserDocument | undefined)[],
    type: "loading" as "loaded" | "loading" | "error",
    total: -1,
    activeRange: undefined as Range | undefined,
    currentUser: null
};

export const userSlice = createSlice({
    name: "user",
    initialState: initialUsers,
    reducers: {
        setType: (state, action: PayloadAction<typeof initialUsers["type"]>) => void (state.type = action.payload),
        setTotal: (state, action: PayloadAction<typeof initialUsers["total"]>) => void (state.total = action.payload),
        setUsers: (state, action: PayloadAction<{ range: Range; users: UserDocument[] }>) => {
            // we need to sort in this users according to the range
            const [from, to] = action.payload.range;
            // make sure state.users is at least **to** long
            if (state.users.length < to) {
                state.users = state.users.concat(Array(to - state.users.length).fill(undefined));
            }
            // and then splice the new values into it
            // @ts-ignore
            state.users.splice(from, to - from, ...action.payload.users);
            // and set the active range
            state.activeRange = action.payload.range;
            state.type = "loaded";
        },
        setUserByIndex: (state, action: PayloadAction<{ index: number; user: UserDocument }>) => {
            // @ts-ignore
            state.users[action.payload.index] = action.payload.user;
        },
        setUserById: (state, action: PayloadAction<{ id: number | string; user: UserDocument }>) => {
            // @ts-ignore
            state.users = state.users.map(u => (u._id === action.payload.id ? (u = action.payload.user) : u));
        },
        setCurrentUser: (state, action: PayloadAction<UserDocument | null>) => {
            state.currentUser = action.payload;
        }
    }
});

export const { setType, setTotal, setUsers, setUserByIndex, setUserById, setCurrentUser } = userSlice.actions;
export default userSlice.reducer;
